import Image from "next/image";
import style from "./Homebanner.module.scss";
import { useTranslation } from "next-i18next";

const HomeBanner = ({ handleClickDiscover }) => {
  const { t } = useTranslation();

  return (
    <div className={style.HomeBanner}>
      <div className={style.HomeBanner__wrapper}>
        <h1 className={style.HomeBanner__title}>{t("home.title")}</h1>
        <div className={style.mobileApp} >
          <div className={style.element} >
            <a target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.ajouve.friendcode" >
              <Image src={'/playStore.svg'} width={200} height={80} />
            </a>
          </div>
          <div className={style.element} >
            <a target="_blank" rel="noreferrer" href="https://apps.apple.com/app/id6743632196" >
              <Image src={'/appStore.svg'} width={200} height={80} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeBanner;
